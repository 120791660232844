<template>
<!--  -->
<v-navigation-drawer width="290" v-model="$store.state.main.navigationDrawerOpened" app fixed disable-resize-watcher :clipped="$vuetify.breakpoint.smAndUp">
    <v-toolbar flat height="70">
        <!-- <v-spacer></v-spacer> -->
        <span class="title primary--text">Каталог товарiв</span>
        <v-spacer></v-spacer>

        <v-btn icon @click.stop="drawerOpenClose()">
            <v-icon>mdi-close</v-icon>
        </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-list class="pa-0 my-2" nav dense v-for="(item, i) in catalogTree().menuItems" :key="i">
        <v-list-group no-action :prepend-icon="item.icon" v-model="lg[i]">
            <template v-slot:activator>
                <v-list-item-title>{{item.text}}</v-list-item-title>
            </template>
            <!-- С вложенными категориями -->
            <v-list-group no-action sub-group  v-for="(subItem, idx) in catalogTree().menuSubItems[item.link]" :key="idx" v-if="catalogTree().menuSubItems[subItem.link]">
                <template v-slot:activator >
                    <v-list-item-content>
                        <v-list-item-title @click="gotoLink(subItem.link)">{{subItem.text}}</v-list-item-title>
                    </v-list-item-content>
                    <v-btn icon v-if="catalogTree().menuSubItems[subItem.link]">
                        <v-icon color="grey lighten-1">menu</v-icon>
                    </v-btn>
                </template>
                <v-list-item v-for="(subItem1, idx1) in catalogTree().menuSubItems[subItem.link]" :key="idx1" link @click.stop="gotoLink(subItem1.link)">
                    <v-list-item-content style="font-size: 13px;">{{subItem1.text}}</v-list-item-content>
                </v-list-item>
            </v-list-group>
            <!-- Без вложенных категорий -->
            <v-list-group no-action sub-group  prepend-icon="n" v-for="(subItem, idx) in catalogTree().menuSubItems[item.link]" :key="idx" v-if="!catalogTree().menuSubItems[subItem.link]">
                <template v-slot:activator >
                    <v-list-item-content>
                        <v-list-item-title @click="gotoLink(subItem.link)">{{subItem.text}}</v-list-item-title>
                    </v-list-item-content>
                    <v-btn icon v-if="catalogTree().menuSubItems[subItem.link]">
                        <v-icon color="grey lighten-1">menu</v-icon>
                    </v-btn>
                </template>
                <v-list-item v-for="(subItem1, idx1) in catalogTree().menuSubItems[subItem.link]" :key="idx1" link @click.stop="gotoLink(subItem1.link)">
                    <v-list-item-content style="font-size: 13px;">{{subItem1.text}}</v-list-item-content>
                </v-list-item>
            </v-list-group>

        </v-list-group>
    </v-list>
    <template v-if="!contactInfo.isSellToEmployee && !contactInfo.isGuest && contactInfo.realUserId > 0">
        <v-divider class="mx-2 mt-4 mb-2"></v-divider>
        <v-list class="pa-0 my-2" nav dense>
            <div class="v-list-group__header v-list-item v-list-item--link theme--light py-1 primary--text" @click="gotoLink('/shopping-guide')">
                <v-list-item-icon>
                    <v-icon color="primary">mdi-basket-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>ТОП пропозиції</v-list-item-title>
            </div>
        </v-list>
        <v-divider class="mx-2 my-2"></v-divider>
    </template>
</v-navigation-drawer>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    props: {
        drawer: Boolean
    },
    data: () => ({
        items: [],
        error: [],
        menuLoading: false,
        status: 0,
        lg: [],
        hidden: false,
    }),
    methods: {
        ...mapActions(['getCatalogTree', 'getBreadCrumbs']),
        ...mapGetters(['catalogTree']),
        gotoLink: function (link) {
            if (typeof link === 'number') {
                if (this.$route.path != `/list/${link}`) {
                    this.$router.push(`/list/${link}`)
                    const options = this.getLocalStorage('catalogListOptions')
                    options.page = 1
                    this.setLocalStorage('catalogListOptions', options)

                }
            } else {
                if (this.$route.path != link) {
                    this.$router.push(link)
                }

            }
        },
        getLocalStorage(f) {
            let value = ''
            try {
                value = localStorage[f] ? JSON.parse(localStorage[f]) : ''
            } catch (error) {
                console.log(error)
                return ''
            }
            return value
        },
        setLocalStorage(f, v) {
            localStorage[f] = JSON.stringify(v)
        },
        activeStatus(s) {
            let status = 0
            if (this.$route.query && this.$route.query.status > 0) {
                status = this.$route.query.status
            }

            return s == '/?status=' + status || (status == 0 && s === '/') ? 'primary--text' : ''
        },
        drawerOpenClose() {
            this.$store.commit('openCloseNavigationDrawer', !this.$store.state.main.navigationDrawerOpened)
        },
    },
    mounted() {
        this.getCatalogTree()
        this.getBreadCrumbs()

    },
    computed: {
        contactInfo() {
            return this.$store.state.main.contactInfo
        },
    }
}
</script>

<style>
.v-list--dense .v-list-item {
    min-height: 20px;
}
</style>
