import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from './views/Home.vue'
import Page404 from './views/Page404.vue'
import store from './store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/new-client',
      name: 'NewClient',
      component: () => import('./views/NewClient.vue')
    },
    {
      path: '/forgot',
      name: 'Forgot',
      component: () => import('./views/Forgot.vue')
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: () => import('./views/Contacts.vue')
    },
    {
      path: '/manager',
      name: 'Manager',
      component: () => import('./views/Manager.vue')
    },
    {
      path: '/scanner',
      name: 'Scanner',
      component: () => import('./views/Scanner.vue')
    },
    {
      path: '/shops',
      name: 'Shops',
      component: () => import('./views/Shops.vue')
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('./views/Search.vue')
    },
    {
      path: '/search-archive',
      name: 'searchArchive',
      component: () => import('./views/SearchArchive.vue')
    },
    {
      path: '/price',
      name: 'Price',
      component: () => import('./views/Price.vue')
    },
    {
      path: '/list/:id',
      component: () => import('./views/List.vue')
    },
    {
      path: '/product/:id',
      name: 'Product',
      component: () => import('./views/Product.vue')
    },
    {
      path: '/contact-info',
      name: 'ContactInfo',
      component: () => import('./views/ContactInfo.vue')
    },
    {
      path: '/checkout/:method/:id',
      name: 'Checkout-success',
      component: () => import('./views/Checkout.vue')
    },
    {
      path: '/checkout',
      name: 'Checkout',
      component: () => import('./views/Checkout.vue')
    },
    {
      path: '/shopping-packet',
      name: 'ShoppingPacket',
      component: () => import('./views/ShoppingPacket.vue')
    },
    {
      path: '/checkout-preorder/:method/:id',
      name: 'Checkout-preorder-success',
      component: () => import('./views/CheckoutPreorder.vue')
    },
    {
      path: '/checkout-preorder',
      name: 'CheckoutPreorder',
      component: () => import('./views/CheckoutPreorder.vue')
    },
    // {
    //   path: '/content',
    //   name: 'Content',
    //   component: () => import('./views/Content.vue')
    // },
    {
      path: '/balance',
      name: 'Balance',
      component: () => import('./views/Balance.vue')
    },
    {
      path: '/service-help',
      name: 'Service Help',
      component: () => import('./views/Service.vue')
    },
    {
      path: '/service-help/:id',
      name: 'Service Help By Id',
      component: () => import('./views/Service.vue')
    },
    {
      path: '/actions',
      name: 'Actions',
      component: () => import('./views/Actions.vue')
    },
    {
      path: '/action/:id',
      name: 'Action',
      component: () => import('./views/Action.vue')
    },
    {
      path: '/sales',
      name: 'Sales',
      component: () => import('./views/Sales.vue')
    },
    {
      path: '/sales-reports',
      name: 'SalesReports',
      component: () => import('./views/Reports.vue')
    },
    {
      path: '/stock-reports',
      name: 'StockReports',
      component: () => import('./views/Reports.vue')
    },
    {
      path: '/imei-reports',
      name: 'ImeiReports',
      component: () => import('./views/ImeiReports.vue')
    },
    {
      path: '/imei-14-reports',
      name: 'Imei14Reports',
      component: () => import('./views/Imei14Reports.vue')
    },
    {
      path: '/delivery-points',
      name: 'DeliveryPoints',
      component: () => import('./views/DeliveryPoints.vue')
    },
    {
      path: '/shopping-guide',
      name: 'ShoppingGuide',
      component: () => import('./views/ShoppingGuide.vue')
    },
    {
      path: '/bonus',
      name: 'Bonus',
      component: () => import('./views/Bonus.vue')
    },
    {
      path: '/payment',
      name: 'Payment',
      component: () => import('./views/Payment.vue')
    },
    {
      path: '/payment-details',
      name: 'PaymentDetails',
      component: () => import('./views/PaymentDetails.vue')
    },
    {
      path: '/reserve',
      name: 'Reserve',
      component: () => import('./views/Reserve.vue')
    },
    {
      path: '/salesgoods',
      name: 'SalesGoods',
      component: () => import('./views/SalesGoods.vue')
    },
    {
      path: '/api-start',
      name: 'UserAPI_start',
      component: () => import('./views/UserAPIStart.vue')
    },
    {
      path: '/api-settings',
      name: 'UserAPI_settings',
      component: () => import('./views/UserAPISettings.vue')
    },
    {
      path: '/api-services',
      name: 'UserAPI_services',
      component: () => import('./views/UserAPI.vue')
    },
    {
      path: '/api-services/:method',
      name: 'UserAPI_Method',
      component: () => import('./views/UserAPI.vue')
    },
    {
      path: '/promua-price-list',
      name: 'UserAPI_promua',
      component: () => import('./views/UserAPIPromua.vue')
    },
    {
      path: '/service-docs',
      name: 'ServiceDocs',
      component: () => import('./views/ServiceDocs.vue')
    },
    {
      path: '/certificates',
      name: 'Certificates/',
      component: () => import('./views/Certificates.vue')
    },
    {
      path: '/repair',
      name: 'Repair',
      component: () => import('./views/Repair.vue')
    },
    {
      path: '/delivery',
      name: 'Delivery',
      component: () => import('./views/Delivery.vue')
    },
    {
      path: '/banners',
      name: 'Banners',
      component: () => import('./views/Banners.vue')
    },
    {
      path: '/banners-placement',
      name: 'BannersPlacement',
      component: () => import('./views/BannersPlacement.vue')
    },
    {
      path: '/motivation/:id',
      name: 'Motivation',
      component: () => import('./views/Motivation.vue')
    },
    {
      path: '/403',
      name: '403',
      component: () => import('./views/Page403.vue')
    },
    {
      path: '/empty',
      name: 'empty',
      component: () => import('./views/Empty.vue')
    },
    {
      path: '/emptyClient',
      name: 'emptyClient',
      component: () => import('./views/EmptyClient.vue')
    },
    {
      path: '/good',
      name: 'goodid',
      component: () => import('./views/Good.vue')
    },
    {
      path: '/good/:id',
      name: 'good',
      component: () => import('./views/Good.vue')
    },
    {
      path: '/good-t22/:id',
      name: 'goodT22',
      component: () => import('./views/GoodT22.vue')
    },
    {
      //checksn
      path: '/checksn',
      name: 'checksn',
      component: () => import('./views/CheckSN.vue')
    },
    // Page Not Foound 404
    {
      path: '*',
      redirect: '/404',
    },
    {
      path: '/404',
      name: '404',
      component: Page404
    }
  ]
})

//Authorization Checking 
router.beforeEach((to, from, next) => {
  if (to.name != 'login'
    && to.name != 'empty'
    && to.name != 'NewClient'
    && to.name != 'emptyClient'
    && to.name != 'Forgot'
    && to.name != 'checksn'
    && to.name != 'good'
    && to.name != 'goodid'
    && to.name != 'goodT22'
  ) {
    store.dispatch('getUserInfo')
      .then(data => {
        if (data.status === 401) {
          next(`/login?url=${to.fullPath}`)
        } else {
          if (!data.is_wholesale_onbehalf_ok && data.client === 0) {
            next('/emptyClient')
          } else if (data.is_wholesale_onbehalf_ok && data.on_behalf_of_user_id === 0) {
            next('/empty')
          } else {
            const userVersion = localStorage.version
            const curVersion = data.version
            // console.log(">>>", userVersion, curVersion)
            if (userVersion && curVersion != userVersion) {
              console.log(userVersion, '->', curVersion)
              setTimeout(() => {
                location.reload(true) //перезагрузка страници
              }, 3000);
            }
            localStorage.version = curVersion
            // Сторінки по яких закриваємо доступ для користувачів які мають ContactInfo.isGuest = 1
            if (to.name === 'ShoppingGuide'
              || to.name === 'ShoppingPacket'
              || to.name === 'Manager'
              || to.name === 'ContactInfo'
              || to.name === 'Price'
              || to.name === 'UserAPI_start'
              || to.name === 'UserAPI_settings'
              || to.name === 'UserAPI_services'
              || to.name === 'UserAPI_promua'
              || to.name === 'PaymentDetails'
              || to.name === 'ImeiReports'
              || to.name === 'Imei14Reports'
              || to.name === 'StockReports'
              || to.name === 'SalesReports'
              || to.name === 'Service Help'
              || to.name === 'Service Help By Id'
              || to.name === 'Actions'
              || to.name === 'Action'
              || to.name === 'Sales'
              || to.name === 'SalesGoods'
              || to.name === 'DeliveryPoints'
              || to.name === 'Bonus'
              || to.name === 'Payment'
              || to.name === 'Reserve'
              || to.name === 'UserAPI_Method'
              || to.name === 'Banners'
              || to.name === 'BannersPlacement'
              || to.name === 'Motivation'
              || to.name === 'Repair'
              || to.name === 'Delivery'
              || to.name === 'ServiceDocs'
              || to.name === 'Certificates') {
              store.dispatch('getContactInfo')
                .then(data => {
                  if (data.isGuest === 1) {
                    next('/403')
                  } else {
                    next()
                  }
                })
            }
            else {
              next()
            }
          }
        }
      })
      .catch(err => {
        if (err && err.response && err.response.status === 401) {
          next(`/login?url=${to.fullPath}`)
        } else {
          next(err)
        }
      })
  } else {
    next()
  }
})

export default router